<template>
    <div>
        <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>
            <a href="#">基础信息管理</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
            <a href="/base/returnInfo">返乡人员管理</a>
        </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form
        ref="queryForm"
        :model="queryModel"
        inline
        class="demo-form-inline"
        >
        <el-form-item label="登记人姓名" prop="name">
            <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
        </el-form-item>
        <el-form-item label="身份证" prop="idCard">
            <el-input
            type="text"
            size="mini"
            v-model="queryModel.idCard"
            ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
            <el-input
            type="text"
            size="mini"
            v-model="queryModel.phone"
            ></el-input>
        </el-form-item>
        <el-form-item label="是否能修改" prop="enableUpdate">
            <el-select size="mini" v-model="queryModel.enableUpdate">
                <el-option
                v-for="enableUpdates in enableUpdateList"
                :key="enableUpdates.id"
                :label="enableUpdates.name"
                :value="enableUpdates.id"
                ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item>
            <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
            >查询</el-button
            >&nbsp;
            <el-button
            type="info"
            size="mini"
            style="margin-left: 8px"
            @click="handleReset('queryForm')"
            >重置</el-button
            >&nbsp;
        </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-table
            :data="tableData"
            style="min-height: 400px"
            v-loading="loading"
            stripe
            @selection-change="handleSelectionChange"
            >
            <el-table-column prop="name" label="登记人姓名" width="120"></el-table-column>
            <el-table-column prop="idCard" label="身份证号" width="180"></el-table-column>
            <el-table-column
                prop="phone"
                label="手机号"
                width="180"
            ></el-table-column>
            <el-table-column
                prop="liveRegionN"
                label="居住地"
                width="180"
            ></el-table-column>
            <el-table-column
                prop="liveDetailAddress"
                label="居住详细地址"
                width="180"
            ></el-table-column>
            <el-table-column
                prop="originN"
                label="始发地"
                width="180"
            ></el-table-column>
            <el-table-column
                prop="originDetailAddress"
                label="始发地详细地址"
                width="180"
            ></el-table-column>
            <el-table-column
                prop="avenuePlace"
                label="途经地"
                width="180"
            ></el-table-column>
            <el-table-column
                prop="vehicle"
                label="交通工具"
                width="180"
            ></el-table-column>
            <el-table-column
                prop="arriveTime"
                label="返乡时间"
                width="180"
            ></el-table-column>
            <el-table-column prop="haveCheck" label="7日阴性核酸检测" width="180">
                <template slot-scope="{row}">
                    <span v-if="row.haveCheck">无</span>
                    <span v-else>有</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="typeN"
                label="返乡类型"
                width="180"
            ></el-table-column>
            <el-table-column prop="enableUpdate" label="是否能修改" width="180">
                <template slot-scope="{row}">
                    <span v-if="row.enableUpdate">是</span>
                    <span v-else>否</span>
                </template>
            </el-table-column>
            <el-table-column prop="checkImageUrl" label="核酸检测报告" width="180">
                <template slot-scope="{ row }">
                    <a :href="row.checkImageUrl" target="_blank">
                        <el-image
                        :size="48"
                        :src="
                            row.checkImageUrl + '?x-oss-process=image/resize,m_fill,w_64,h_64'
                        "
                        :key="row.id"
                        ><div slot="error">
                            <i class="el-icon-picture-outline" style="width:64px;"></i></div
                        ></el-image>
                    </a>
                </template>
            </el-table-column>
            <el-table-column prop="travelImageUrl" label="行程卡图片" width="180">
                <template slot-scope="{ row }">
                    <a :href="row.travelImageUrl" target="_blank">
                        <el-image
                        :size="48"
                        :src="
                            row.travelImageUrl + '?x-oss-process=image/resize,m_fill,w_64,h_64'
                        "
                        :key="row.id"
                        ><div slot="error">
                            <i class="el-icon-picture-outline" style="width:64px;"></i></div
                        ></el-image>
                    </a>
                </template>
            </el-table-column>
            <el-table-column prop="healthCodeUrl" label="健康码" width="180">
                <template slot-scope="{ row }">
                    <a :href="row.healthCodeUrl" target="_blank">
                        <el-image
                        :size="48"
                        :src="
                            row.healthCodeUrl + '?x-oss-process=image/resize,m_fill,w_64,h_64'
                        "
                        :key="row.id"
                        ><div slot="error">
                            <i class="el-icon-picture-outline" style="width:64px;"></i></div
                        ></el-image>
                    </a>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
        <returnInfo-detail
        v-if="showModal"
        :businessKey="businessKey"
        :title="modalTitle"
        @close="onDetailModalClose"
        ></returnInfo-detail>
    </div>
</template>
<script>
import returnInfoApi from "@/api/base/returnInfo";
import returnInfoDetail from "./returnInfo-detail";
export default {
    name: "baseReturnInfoList",
    data() {
        return {
        queryModel: {
            name:"",
            idCard:"",
            phone:"",
            enableUpdate:"",
        },
        loading: false,
        tableData: [],
        pageIndex: 1,
        pageSize: 10,
        totalPages: 0,
        totalElements: 0,
        field: "",
        direction: "",
        pageSizeList: [10, 20, 30],
        multipleSelection: [],
        showModal: false,
        modalTitle: "",
        businessKey: "",
        enableUpdateList:[{
            name:"是",
            id:true
        },
        {
            name:"否",
            id:false
        }]
        };
    },
    methods: {
        changePage(pageIndex) {
            var self = this;

            self.loading = true;

            self.pageIndex = pageIndex;
            var formData = new FormData();

            formData.append("pageIndex", self.pageIndex);
            formData.append("pageSize", self.pageSize);

            formData.append("name", self.queryModel.name);
            formData.append("idCard", self.queryModel.idCard);
            formData.append("phone", self.queryModel.phone);
            formData.append("enableUpdate", self.queryModel.enableUpdate);

            returnInfoApi
                .pageList(formData)
                .then(function (response) {
                    self.loading = false;

                    var jsonData = response.data.data;

                    self.tableData = jsonData.data;
                    self.totalPages = jsonData.totalPages;
                    self.totalElements = jsonData.recordsTotal;
                })
                .catch((error) => {
                self.loading = false;
                // self.$message.error(error + "");
                });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;

            this.$nextTick(() => {
                this.changePage(this.pageIndex);
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        handleAdd() {
            this.modalTitle = "新增";
            this.businessKey = "";
            this.showModal = true;
        },
        handleEdit(record) {
            this.modalTitle = "编辑";
            this.businessKey = record.id;
            this.showModal = true;
        },
        handleDelete(record) {
            var self = this;

            self.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                })
                .then(() => {
                returnInfoApi.remove(record.id).then(function (response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                    // var index = self.tableData.indexOf(record);
                    // self.tableData.splice(index, 1);
                    self.changePage(self.pageIndex);

                    self.$message({
                        type: "success",
                        message: "删除成功!",
                    });
                    }
                });
            });
        },
        handleBatchDelete() {
            var self = this;

            var idList = this.multipleSelection.map((record) => {
                return record.id;
            });

            this.$confirm("是否确认删除选中项？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                returnInfoApi.batchRemove(idList).then(function (response) {
                var jsonData = response.data;

                if (jsonData.result) {
                    self.changePage(self.pageIndex);

                    self.$message({
                    type: "success",
                    message: "删除成功!",
                    });
                }
                });
            });
        },
        onDetailModalClose(refreshed) {
            //保存成功后回调
            this.showModal = false;

            if (refreshed) {
                this.changePage(this.pageIndex);
            }
        },
    },
    mounted: function () {
        this.changePage(1);
    },
    components: {
        "returnInfo-detail": returnInfoDetail,
    },
}
</script>
<style scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>